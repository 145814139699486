export const NAVMENUS = [
  {
    id: 1,
    title: 'Home',
    link: '#home',
  },
  // {
  //   id: 2,
  //   title: 'About us',
  //   link: '#workstep'
  // },
  {
    id: 3,
    title: 'Services',
    link: '#services'
  },
  // {
  //   id: 4,
  //   title: 'Team',
  //   link: '#team'
  // },
  {
    id: 5,
    title: 'Case studio',
    link: '#studio'
  },
  {
    id: 6,
    title: 'Contacts',
    link: '#contacts'
  }
];
