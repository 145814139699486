import StepImage1 from '@/assets/images/home/4.jpg';
import NumberImage1 from '@/assets/images/number-01.svg';
import NumberImage2 from '@/assets/images/number-02.svg';
import StepImage2 from '@/assets/images/home/5.jpg';
import StepImage3 from '@/assets/images/home/6.jpg';
import NumberImage3 from '@/assets/images/number-03.svg';
import NumberImage4 from '@/assets/images/number-04.svg';
import StepImage4 from '@/assets/images/home/7.jpg';
import StepImage5 from '@/assets/images/home/8.jpg';
import NumberImage5 from '@/assets/images/number-05.svg';

export const WORKSTEPS = [
  {
    id: 1,
    cardImage: StepImage1,
    numberImage: NumberImage1,
    title: 'Meeting With The Customer',
    description: 'The first meeting is very important to form contact and discuss the main ideas of the project.'
  },
  {
    id: 2,
    cardImage: StepImage2,
    numberImage: NumberImage2,
    title: 'We Consider And Analyze The Work Plan',
    description: 'When we understand the main ideas, we proceed to discussing the work plan and analyzing it.'
  },
  {
    id: 3,
    cardImage: StepImage3,
    numberImage: NumberImage3,
    title: 'Work Hard On The Project',
    description: 'Our professional team start to handle with your project and embody the conception of the project.'
  },
  {
    id: 4,
    cardImage: StepImage4,
    numberImage: NumberImage4,
    title: 'Once Again We Analyze And Check Everything',
    description: 'We meet, analyze and check the done work and make needed corrections.'
  },
  {
    id: 5,
    cardImage: StepImage5,
    numberImage: NumberImage5,
    title: 'We Finish The Work And Give It To The Customer',
    description: 'The project is ready and we give it to the customer. Also we ask for the feedback from the customer.'
  }
];
