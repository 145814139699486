import React from 'react';

import { Button } from '@/components/common/Button';
import { Container } from '@/components/Container';

const Studio = () => (
  <section
    id="studio"
    className="bg-blue text-white mb-16"
  >
    <Container>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 py-16">
        <div className="grid grid-cols-1 xs:grid-cols-2 gap-6">
          <div className="p-8 bg-blue-500 rounded-lg hover:shadow-2xl">
            <p className="text-4xl mb-4">36</p>
            <span>Completed projects to the end by our specialists.</span>
          </div>
          <div className="p-8 bg-blue-500 rounded-lg hover:shadow-2xl">
            <p className="text-4xl mb-4">43</p>
            <span>A large team of the best specialists.</span>
          </div>
          <div className="p-8 bg-blue-500 rounded-lg hover:shadow-2xl">
            <p className="text-4xl mb-4">152</p>
            <span>Customers who were satisfied with our work</span>
          </div>
          <div className="p-8 bg-blue-500 rounded-lg hover:shadow-2xl">
            <p className="text-4xl mb-4">99%</p>
            <span>Our values are good organization and result orientation.</span>
          </div>
        </div>
        <div className="w-full flex flex-col items-start gap-y-6">
          <h1 className="text-4xl">Why choose our studio for your projects?</h1>
          <p>
            We are a command of professional and enthusiastic people, who like what
            they do and are always in the process of growing and improving their skills.
          </p>
          <Button
            variant="outlined"
            className="border-white !text-white"
          >
            Order Service
          </Button>
        </div>
      </div>
    </Container>
  </section>
);

export { Studio };
