import React from 'react';

import { Button } from '@/components/common/Button';
import CreativityImage1 from '@/assets/images/home/1.jpg';
import CreativityImage2 from '@/assets/images/home/2.jpg';
import { moveToSection } from '@/utils';
import { Container } from '@/components';

const Home = () => {
  return (
    <section id="home" className="mb-16">
      <div className="relative py-20">
        <Container>
          <div className="flex flex-col justify-center">
            <h1 className="text-4xl mb-8">Our creativity is your success</h1>
            <p className="mb-8 text-black">
              we are a dynamic software company committed to translating creativity into your success. With a team of forward-thinking professionals, we craft customized solutions that cater to your unique business needs, leveraging the latest technologies and industry insights. From robust software development to intuitive user interfaces, our focus is on empowering your growth and enhancing your competitive edge in the digital sphere. Partner with us to elevate your business to new heights and realize your full potential.
            </p>
            <div className="flex flex-row sm:flex-col md:flex-row items-start md:items-center space-2">
              <Button className="!px-5" onClick={() => moveToSection('workstep')}>Learn More</Button>
              {/*<button className="uppercase text-gray-500 mt-0 sm:mt-2 md:mt-0 px-5 sm:px-2 md:px-5 py-2">*/}
              {/*  Watch Video*/}
              {/*  <span className="ml-2 text-gray-400">*/}
              {/*    <i className="fa fa-play-circle text-lg font-normal" />*/}
              {/*  </span>*/}
              {/*</button>*/}
            </div>
          </div>
        </Container>

        <div
          className="absolute left-0 top-0 w-full h-full opacity-20 bg-cover z-[-1] rounded"
          style={{ backgroundImage: `url(${CreativityImage1})` }}
        >
        </div>
      </div>


      <Container>
        <div
          className="grid grid-cols-1 sm:grid-cols-2 gap-8 mt-10 sm:mt-0 py-10">
          <div
            className="hidden sm:block rounded-tr-[100px] rounded-bl-[100px] bg-cover bg-right"
            style={{ backgroundImage: `url(${CreativityImage2})` }}
          >
          </div>
          <div className="flex flex-col justify-center py-5 md:py-20">
            <h1 className="text-4xl pt-10 sm:pt-0 mb-8">
              Creative Challenges are our passion
            </h1>
            <p className="mb-8 text-gray-400">
              Our team of designers and developers work closely together to build a
              creative and positive working environment. Functionality is as
              important to us as aesthetics.
            </p>
            <div>
              <Button className="mr-2">About us</Button>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => moveToSection('contacts')}
              >
                Contact Us
              </Button>
            </div>
          </div>
          <div className="sm:hidden flex justify-center items-center pt-5">
            <img src={CreativityImage2} alt={CreativityImage2} className="rounded" />
          </div>
        </div>
      </Container>

    </section>
  );
};

export { Home };
