import React from 'react';

import { Container, Layout } from '@/components';

import { Home } from '@/pages/main/Home';
import { Services } from '@/pages/main/Services';
import { Studio } from '@/pages/main/Studio';
import { Workstep } from '@/pages/main/Workstep';
// import { Team } from '@/pages/main/Team';
import { Contacts } from '@/pages/main/Contacts';
// import { Subscribe } from '@/pages/main/Subscribe';

const Main = () => (
  <Layout>
    <Home />
    <Services />

    <Studio />

    <Container>
      <Workstep />
    </Container>

    {/*<Team />*/}

    <Container>
      <Contacts />
    </Container>

    {/*<Subscribe />*/}
  </Layout>
);

export default Main;
