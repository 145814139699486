export const SERVICES = [
  {
    id: 0,
    title: 'Web Development',
    description: 'Our expert team specializes in creating responsive and visually appealing websites, utilizing the latest technologies and' + 'industry best practices',
    icon: 'paint-brush'
  },
  {
    id: 1,
    title: 'Web Design & Brending',
    description: 'A great brand combines design and conveys company\'s value messaging to evoke emotion and drive' +
        'strategy.',
    icon: 'paint-brush'
  },
  {
    id: 2,
    title: 'Graphic Design',
    description: 'Rich experience plus unlimited imagination create our successful service discovered by clients.',
    icon: 'code'
  },
  {
    id: 3,
    title: 'Video Production',
    description: 'Creating and handling professional video is our strong point. It plays major role in your digital' +
        'business strategy.',
    icon: 'gears'
  },
  {
    id: 4,
    title: 'SEO',
    description: 'We provide everything your site needs to perform at it\'s highest capabilities on the major' +
        'search engines.',
    icon: 'server'
  },
  {
    id: 6,
    title: 'Infographics',
    description: 'Turn your data into captivating artwork that will keep your audience engaged. Our team can help' +
        'you with it.',
    icon: 'comments'
  }
];
