import React, { useEffect, useState } from 'react';

const StepLine1 = () => {
  const [firstPosX, setFirstPosX] = useState<number>(0);
  const [firstPosY, setFirstPosY] = useState<number>(0);
  const [firstCurveHeight, setFirstCurveHeight] = useState<number>(0);
  const [centerPosX, setCenterPosX] = useState<number>(0);
  const [centerCurveHeight, setCenterCurveHeight] = useState<number>(0);
  const [lastPosX, setLastPosX] = useState<number>(0);
  const [lastCurveHeight, setLastCurveHeight] = useState<number>(0);
  const [arrowPosY, setArrowPosY] = useState<number>(0);

  useEffect(() => {
    onChangeWidth();
    window.addEventListener('resize', () => {
      onChangeWidth();
    });

    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  const onChangeWidth = () => {
    const width = document.documentElement.clientWidth;
    if (width < 912 && width >= 760) {
      setFirstPosX((width - 768) * 7 / 10 + 320 + 40 + 36);
      setFirstPosY((width - 80) / 6 + 36 + (width - 768) / 6 + 6);
      setFirstCurveHeight(36 + (width - 768) * 5 / 24);
      setCenterPosX((width - 80) / 2 - 164);
      setCenterCurveHeight(32 + (width - 768) / 18);
      setLastPosX((width - 768) / 5 + 106);
      setLastCurveHeight((width - 768) / 12 + 60);
      setArrowPosY(((width - 768) * 7 / 10 * 213.33 / 320 + 213.33) * 3 / 2 + 64 - 36 - 30);
    } else if (width < 1024) {
      setFirstPosX((width - 80) / 2 + 40 + 36);
      setFirstPosY((width - 80) / 6 + 36 + 30);
      setFirstCurveHeight(60);
      setCenterPosX((width - 80) / 2 - 160);
      setCenterCurveHeight(40);
      setLastPosX((width - 912) / 40 + 124);
      setLastCurveHeight((width - 912) * 5 / 14 + 72);
      setArrowPosY((width - 80) / 3 + 64 + (width - 80) / 6 - 36 - 30);
    }
  };

  return (
    <div className="hidden md:block absolute top-30 left-0">
      <svg width="700" height="600" viewBox="0 0 700 600" fill="none" strokeWidth={3} stroke="rgb(31, 221, 128)">
        <path
          d="M650 240 q 0 100 -50 130 -100 60 -300 50 -100 0 -120 95"
          strokeDasharray="10,10"
          className="hidden 2xl:block"
        />
        <path d="M170 515 177 524 188 518" className="hidden 2xl:block" />
        <path
          d="M520 200 q 0 60 -50 90 -50 40 -200 25 -100 -10 -120 80"
          strokeDasharray="10,10"
          className="hidden lg:block 2xl:hidden"
        />
        <path d="M142 389 149 398 159 392" className="hidden lg:block 2xl:hidden" />
        <path
          d={
            `M${firstPosX} ${firstPosY} q 0 ${firstCurveHeight} -50 ${firstCurveHeight + 30} -50 ${centerCurveHeight}`
            + ` -${centerPosX} ${centerCurveHeight - 5} -${lastPosX} -10 -${lastPosX + 10} ${lastCurveHeight}`
          }
          strokeDasharray="10,10"
          className="block lg:hidden"
        />
        <path d={`M42 ${arrowPosY - 9} 49 ${arrowPosY} 58 ${arrowPosY - 6}`} className="block lg:hidden" />
      </svg>
    </div>
  );
};

const StepLine2 = () => {
  const [topPosY, setTopPosY] = useState<number>(0);
  const [firstPosY, setFirstPosY] = useState<number>(0);
  const [centerPosX, setCenterPosX] = useState<number>(0);
  const [lastPosX, setLastPosX] = useState<number>(0);
  const [lastCurveX, setLastCurveX] = useState<number>(0);
  const [lastCurveHeight, setLastCurveHeight] = useState<number>(0);
  const [arrowPosX, setArrowPosX] = useState<number>(0);
  const [arrowPosY, setArrowPosY] = useState<number>(0);

  useEffect(() => {
    onChangeWidth();
    window.addEventListener('resize', () => {
      onChangeWidth();
    });

    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  const onChangeWidth = () => {
    const width = document.documentElement.clientWidth;
    if (width < 912 && width >= 760) {
      setTopPosY((width - 80) / 2 + 64 + 70 + 34.92 * (width - 760) / (912 - 760));
      setFirstPosY(80);
      setCenterPosX((width - 768) * 5 / 12 + 160);
      setLastPosX((width - 768) * 5 / 18 + 40);
      setLastCurveX((width - 768) * 11 / 36 + 52);
      setLastCurveHeight((width - 768) / 4 + 44);
      setArrowPosX((width - 768) * 17 / 24 + 373);
      setArrowPosY((width - 768) * 11 / 48 + 217);
    } else if (width < 1024) {
      setTopPosY((width - 80) / 2 + 64 + 104.92);
      setFirstPosY((width - 912) * 5 / 28 + 80);
      setCenterPosX((width - 912) * 15 / 28 + 220);
      setLastPosX(80);
      setLastCurveX((width - 912) / 28 + 96);
      setLastCurveHeight((width - 912) * 5 / 28 + 80);
      setArrowPosX((width - 912) * 61 / 112 + 475);
      setArrowPosY((width - 912) * 5 / 14 + 250);
    } else if (width >= 1024) {
      setTopPosY(620);
    }
  };

  return (
    <div
      className="hidden md:block absolute left-0"
      style={{
        top: `${topPosY}px`
      }}
    >
      <svg width="700" height="600" viewBox="0 0 700 600" fill="none" strokeWidth={3} stroke="rgb(31, 221, 128)">
        <path
          d="M160 160 q 20 130 320 120 160 -10 170 120"
          strokeDasharray="10,10"
          className="hidden 2xl:block"
        />
        <path d="M640 402 650 410 659 401" className="hidden 2xl:block" />
        <path
          d="M130 20 q 20 100 220 80 140 -10 160 100"
          strokeDasharray="10,10"
          className="hidden lg:block 2xl:hidden"
        />
        <path d="M502 203 510 208 518 202" className="hidden lg:block 2xl:hidden" />
        <path
          d={`M36 78 q 20 ${firstPosY} 120 ${firstPosY} 120 0 ${centerPosX} 5 ${lastPosX} 10 ${lastCurveX} ${lastCurveHeight}`}
          strokeDasharray="10,10"
          className="block lg:hidden"
        />
        <path
          d={`M${arrowPosX - 13} ${arrowPosY - 5} ${arrowPosX} ${arrowPosY} ${arrowPosX + 8} ${arrowPosY - 10}`}
          className="block lg:hidden"
        />
      </svg>
    </div>
  );
};

const StepLine3 = () => {
  const [topPosY, setTopPosY] = useState<number>(0);
  const [firstPosX, setFirstPosX] = useState<number>(0);
  const [firstPosY, setFirstPosY] = useState<number>(0);
  const [firstCurveHeight, setFirstCurveHeight] = useState<number>(0);
  const [centerPosX, setCenterPosX] = useState<number>(0);
  const [centerCurveHeight, setCenterCurveHeight] = useState<number>(0);
  const [lastPosX, setLastPosX] = useState<number>(0);
  const [lastCurveHeight, setLastCurveHeight] = useState<number>(0);
  const [arrowPosY, setArrowPosY] = useState<number>(0);

  useEffect(() => {
    onChangeWidth();
    window.addEventListener('resize', () => {
      onChangeWidth();
    });

    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  const onChangeWidth = () => {
    const width = document.documentElement.clientWidth;
    if (width < 912 && width >= 760) {
      setTopPosY(920 - (912 - width) + (912- width) / 6);
      setFirstPosX((width - 768) * 7 / 10 + 386 + Math.max(780 - width, 0));
      setFirstPosY((width - 80) / 6 + 36 + (width - 768) / 6 - 160 + Math.max(780 - width, 0));
      setFirstCurveHeight(36 + (width - 768) * 5 / 24 - 10);
      setCenterPosX((width - 80) / 2 - 164);
      setCenterCurveHeight(32 + (width - 768) / 18);
      setLastPosX((width - 768) / 5 + 106 - 10 + Math.max(780 - width, 0));
      setLastCurveHeight((width - 768) / 12 + 60 - 5);
      setArrowPosY(((width - 768) * 7 / 10 * 213.33 / 320 + 213.33) * 3 / 2 - 182 + Math.max(784 - width, 0));
    } else if (width < 1024) {
      setTopPosY(920 - (1024 - width) / 2);
      setFirstPosX((width - 80) / 2 + 73 + (width - 912) / 39);
      setFirstPosY((width - 80) / 6 - 34);
      setFirstCurveHeight(60);
      setCenterPosX((width - 80) / 2 - 160);
      setCenterCurveHeight(40);
      setLastPosX((width - 912) / 40 + 124);
      setLastCurveHeight((width - 912) * 5 / 14 + 52);
      setArrowPosY((width - 80) / 3 + 64 + (width - 80) / 6 - 190);
    } else if (width >= 1024) {
      setTopPosY(920);
    }
  };

  return (
    <div
      className="hidden md:block absolute left-0"
      style={{
        top: `${topPosY}px`
      }}
    >
      <svg
        width="700"
        height="600"
        viewBox="0 0 700 600"
        fill="none"
        strokeWidth={3}
        stroke="rgb(31, 221, 128)"
      >
        <path
          d="M650 240 q 0 100 -50 130 -100 60 -300 50 -100 0 -120 60"
          strokeDasharray="10,10"
          className="hidden 2xl:block"
        />
        <path d="M172 473 177 484 190 480" className="hidden 2xl:block" />
        <path
          d="M510 30 q 0 100 -240 100 -100 0 -120 60"
          strokeDasharray="10,10"
          className="hidden lg:block 2xl:hidden"
        />
        <path d="M142 189 148 197 157 193" className="hidden lg:block 2xl:hidden" />
        <path
          d={
            `M${firstPosX} ${firstPosY} q 0 ${firstCurveHeight} -50 ${firstCurveHeight + 30} -50 ${centerCurveHeight}`
            + ` -${centerPosX} ${centerCurveHeight - 5} -${lastPosX} -10 -${lastPosX + 10} ${lastCurveHeight}`
          }
          strokeDasharray="10,10"
          className="block lg:hidden"
        />
        <path
          d={`M42 ${arrowPosY - 9} 49 ${arrowPosY} 58 ${arrowPosY - 6}`}
          className="block lg:hidden"
        />
      </svg>
    </div>
  );
};

const StepLine4 = () => {
  const [topPosY, setTopPosY] = useState<number>(0);
  const [firstPosY, setFirstPosY] = useState<number>(0);
  const [centerPosX, setCenterPosX] = useState<number>(0);
  const [lastPosX, setLastPosX] = useState<number>(0);
  const [lastCurveX, setLastCurveX] = useState<number>(0);
  const [lastCurveHeight, setLastCurveHeight] = useState<number>(0);
  const [arrowPosX, setArrowPosX] = useState<number>(0);
  const [arrowPosY, setArrowPosY] = useState<number>(0);

  useEffect(() => {
    onChangeWidth();
    window.addEventListener('resize', () => {
      onChangeWidth();
    });

    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  const onChangeWidth = () => {
    const width = document.documentElement.clientWidth;
    if (width < 912 && width >= 760) {
      setTopPosY(1200 - (932 - width) * 1.4);
      setFirstPosY(80);
      setCenterPosX((width - 768) * 5 / 12 + 160);
      setLastPosX((width - 768) * 5 / 18 + 40);
      setLastCurveX((width - 768) * 11 / 36 + 52);
      setLastCurveHeight((width - 768) / 4 + 44);
      setArrowPosX((width - 768) * 17 / 24 + 373);
      setArrowPosY((width - 768) * 11 / 48 + 217);
    } else if (width < 1024) {
      setTopPosY(1200 + (width - 932));
      setFirstPosY((width - 912) * 5 / 28 + 80);
      setCenterPosX((width - 912) * 15 / 28 + 220);
      setLastPosX(80);
      setLastCurveX((width - 912) / 28 + 96);
      setLastCurveHeight((width - 912) * 5 / 28 + 80);
      setArrowPosX((width - 912) * 61 / 112 + 475);
      setArrowPosY((width - 912) * 5 / 14 + 250);
    } else if (width >= 1024) {
      setTopPosY(1200);
    }
  };

  return (
    <div
      className="hidden md:block absolute left-0"
      style={{
        top: `${topPosY}px`
      }}
    >
      <svg
        width="700"
        height="600"
        viewBox="0 0 700 600"
        fill="none"
        strokeWidth={3}
        stroke="rgb(31, 221, 128)"
      >
        <path
          d="M160 320 q 20 130 320 120 160 -10 170 120"
          strokeDasharray="10,10"
          className="hidden 2xl:block"
        />
        <path d="M640 562 650 570 659 561" className="hidden 2xl:block" />
        <path
          d="M130 40 q 20 100 220 80 140 -10 160 90"
          strokeDasharray="10,10"
          className="hidden lg:block 2xl:hidden"
        />
        <path d="M502 213 510 218 518 212" className="hidden lg:block 2xl:hidden" />
        <path
          d={
            `M36 78 q 20 ${firstPosY} 120 ${firstPosY} 120 0 ${centerPosX} 5`
              + ` ${lastPosX} 10 ${lastCurveX} ${lastCurveHeight}`
          }
          strokeDasharray="10,10"
          className="block lg:hidden"
        />
        <path
          d={
            `M${arrowPosX - 13} ${arrowPosY - 5} ${arrowPosX} ${arrowPosY}`
              + ` ${arrowPosX + 8} ${arrowPosY - 10}`
          }
          className="block lg:hidden"
        />
      </svg>
    </div>
  );
};

export const WorkStepLines = () => (
  <>
    <StepLine1 />
    <StepLine2 />
    <StepLine3 />
    <StepLine4 />
  </>
);
