import React, { ReactNode } from 'react';

interface IContainerProps {
  children?: ReactNode
}

const Container = ({ children }: IContainerProps) => (
  <div className="max-w-screen-sm 2xl:max-w-screen-xl sm:max-w-screen-lg mx-auto px-5 py-0 sm:py-5 xs:px-10 md:px-5">
    { children }
  </div>
);

export { Container };
