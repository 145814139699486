import React from 'react';

import { Button } from '@/components/common/Button';
import SubscribeImage from '@/assets/images/home/9.jpg';

const Contacts = () => (
  <section
    id="contacts"
    className="mb-16">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 py-16">
      <div
        className="bg-cover bg-no-repeat rounded-tl-[100px] rounded-br-[100px] bg-center min-h-75"
        style={{ backgroundImage: `url(${SubscribeImage})` }}
      >

      </div>
      <div className="flex flex-col justify-center gap-y-6 bg-white rounded-lg shadow-2xl py-16 px-6 md:px-16">
        <h1 className="text-4xl">Sign up for our consultation</h1>
        <input
          placeholder="Name"
          className="px-4 py-2 border border-gray-400 rounded outline-none"
        />
        <input
          placeholder="Email"
          className="px-4 py-2 border border-gray-400 rounded outline-none"
        />
        <textarea
          rows={4}
          placeholder="Message"
          className="px-4 py-2 border border-gray-400 rounded outline-none"
        />
        <Button className="self-start">Send</Button>
      </div>
    </div>
  </section>
);

export { Contacts };
