import React, { ReactNode } from 'react';

import { Footer, Header } from '@/components';

interface ILayoutProps {
  children: ReactNode
}

const Layout = ({ children }: ILayoutProps) => (
  <div className="w-screen">
    <Header />
    { children }
    <Footer />
  </div>
);

export { Layout };
