import React from 'react';

interface IServiceCardProps {
  title: string,
  description: string,
  icon: string
}

const ServiceCard = ({
  title,
  description,
  icon
}: IServiceCardProps) => (
  <div className="group relative flex flex-col items-start gap-y-6 bg-white hover:shadow-xl rounded-lg p-8">
    <span className="flex items-center justify-center bg-primary rounded-full w-16 h-16 shadow-step-number">
      <i className={`fa fa-${icon} text-xl text-white`} />
    </span>
    <h6 className="text-2xl">{title}</h6>
    <p className="text-gray-500">{description}</p>
    {/*<button className="uppercase">*/}
    {/*  Read More*/}
    {/*  <i className="fa fa-long-arrow-alt-right ml-2" />*/}
    {/*</button>*/}
  </div>
);

export { ServiceCard };
