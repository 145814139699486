import React from 'react';

import { Button } from '@/components/common/Button';
import { Container, ServiceCard } from '@/components';
import { SERVICES } from '@/constants';

import ContactImage from '@/assets/images/home/3.jpg';
import { moveToSection } from '@/utils';

const Services = () => {
  const moveToContact = () => {
    moveToSection('contacts');
  };

  return (
    <section id="services" className="mb-16">
      <Container>
        <h1 className="relative text-4xl mb-8">
          We provide a wide range of creative services
          <div className="absolute top-1 right-0 w-0 h-full bg-white animation-fade-decrease" />
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-8 mb-8">
          {SERVICES.map((service) => (
            <ServiceCard key={service.id} {...service} />
          ))}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="flex flex-col gap-y-8 justify-center mt-10 md:mt-0">
            <h1 className="text-4xl">
              If you have any questions, please contact us
            </h1>
            <p className="text-gray-500">
              Our professional support team try to do their best to provide you
              with high-quality consultations concerning any questino about our
              services.
            </p>
            <div className="flex gap-4">
              <Button onClick={moveToContact}>Contact Us</Button>
              {/*<button className="uppercase font-bold bg-white border border-gray-200 text-gray-400 rounded px-4 py-2">*/}
              {/*  Subscribe*/}
              {/*</button>*/}
            </div>
          </div>
          <div className="flex justify-center md:justify-start">
            <img
              src={ContactImage}
              alt="Contact Image"
              className="max-h-110 w-full rounded sm:w-fit sm:rounded-tr-[100px] sm:rounded-bl-[100px]"
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export { Services };
