import React from 'react';
import clsx from 'clsx';

interface IStepCardProps {
  cardImage: any;
  numberImage: any;
  title: string;
  description: string;
  align: 'left' | 'right';
}

const WorkstepCard = ({
  cardImage,
  numberImage,
  title,
  description,
  align,
}: IStepCardProps) => {
  const alignClass = align === 'left' ? 'flex-row' : 'flex-row-reverse';
  const orderClass = align === 'right' ? 'md:order-1' : '';

  return (
    <div className={`flex ${alignClass}`}>
      <div
        className={`${alignClass} flex flex-col md:flex-row gap-x-10 lg:gap-x-20 gap-y-8 w-full lg:max-w-9/10`}
      >
        <div className={`${orderClass} min-w-80 w-full md:w-fit md:max-w-1/2`}>
          <img
            alt="CardImage"
            src={cardImage}
            className={clsx('mx-auto ', {
              'rounded-tr-[100px] rounded-bl-[100px]': align === 'right',
              'rounded-tl-[100px] rounded-br-[100px]': align === 'left',
            })}
          />
        </div>
        <div className="flex items-center gap-x-6">
          <img
            src={numberImage}
            alt="NumberImage"
            className="w-15 md:w-18 h-15 md:h-18 rounded-full shadow-step-number"
          />
          <div>
            <h5 className="text-2xl mb-4">{title}</h5>
            <p className="text-gray-500">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { WorkstepCard };
