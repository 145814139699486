import React from 'react';

import { WorkstepCard } from '@/components';
import { WorkStepLines } from '@/components/WorkstepLine';
import { WORKSTEPS } from '@/constants';

const Workstep = () => (
  <section
    id="workstep"
    className="relative mb-16">
    <h1 className="text-4xl mb-10">Steps of our work</h1>
    <div className="flex flex-col gap-y-16 py-8">
      {
        WORKSTEPS.map((step, index) => <WorkstepCard
          key={step.id}
          align={index % 2 ? 'right' : 'left'}
          {...step}
        />)
      }
    </div>
    <WorkStepLines />
  </section>
);

export { Workstep };
