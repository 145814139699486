import React, { useEffect, useState } from 'react';

import { Container } from '@/components/Container';

import { NAVMENUS } from '@/constants';

import Logo from '@/assets/images/logo.svg';
import clsx from 'clsx';

interface INavMenuItem {
  id: number;
  title: string;
  link: string;
}

const Header = () => {
  const [hasScrolled, setHasScrolled] = useState(false);

  const navRef = React.createRef<HTMLUListElement>();
  const [activeNav, setActiveNav] = useState<number | undefined>(1);

  const activeClass = 'border-b-2 border-gray-500';

  const toggleNavMenu = () => {
    if (!navRef.current) return;
    navRef.current.classList.toggle('hidden');
  };

  const hideNavMenu = (menuItem?: INavMenuItem) => {
    if (!navRef.current) return;
    navRef.current.classList.add('hidden');
    setActiveNav(menuItem?.id);

    if (menuItem) {
      const element = document.querySelector(menuItem.link);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setHasScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const listener = (event: any) => {
      if (!navRef.current || navRef.current.contains(event.target)) {
        return;
      }
      navRef.current.classList.add('hidden');
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [navRef]);

  return (
    <div
      className={clsx('sticky top-0 bg-white z-20 py-2 sm: py-0', {
        'shadow-lg': hasScrolled,
      })}
    >
      <Container>
        <div className="w-full flex items-center justify-between">
          <div className="h-15">
            <img src={Logo} alt="techno-long-solution" className="h-full" />
          </div>
          <div className="flex items-center gap-x-6">
            <ul className="hidden lg:flex 2xl:gap-x-8 gap-x-4">
              {NAVMENUS.map((menuItem) => (
                <li
                  key={menuItem.id}
                  className={`py-1 cursor-pointer ${
                    activeNav === menuItem.id ? activeClass : ''
                  }`}
                  onClick={() => hideNavMenu(menuItem)}
                >
                  {menuItem.title}
                </li>
              ))}
            </ul>
            <div className="relative">
              <i
                className="fa fa-navicon lg:hidden cursor-pointer"
                onClick={toggleNavMenu}
              />
              <ul
                ref={navRef}
                className="w-40 absolute right-0 transform rounded-xl bg-white shadow-xl p-4 hidden mt-2"
              >
                {NAVMENUS.map((menuItem) => (
                  <li
                    key={menuItem.id}
                    className={`group relative py-1 border-b-2 cursor-pointer ${
                      activeNav === menuItem.id
                        ? 'border-gray-500'
                        : 'border-transparent'
                    }`}
                    onClick={() => hideNavMenu(menuItem)}
                  >
                    {menuItem.title}
                    <div
                      className={`hidden ${
                        activeNav === menuItem.id ? '' : 'group-hover:block'
                      } 
                      absolute bottom-0 left-0 h-0.5 w-full bg-gray-500 animation-fade-increase`}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export { Header };
