import React from 'react';
import clsx from 'clsx';

interface IButtonProps {
  type?: 'button' | 'submit' | 'reset';
  color?: 'primary' | 'secondary';
  variant?: 'contained' | 'outlined';
  className?: string;
  onClick?: () => void;
  children: React.ReactNode;
}

export const Button = ({
  type = 'button',
  color = 'primary',
  variant = 'contained',
  className,
  onClick,
  children
}: IButtonProps) => {
  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <button
      type={type}
      className={clsx(
        'custom-button relative rounded px-4 py-2 hover:shadow-xl uppercase overflow-hidden',
        { 'text-white bg-primary': color === 'primary' && variant !== 'outlined' },
        { 'font-bold text-gray-500 border-gray-200': color === 'secondary' && variant !== 'outlined' },
        { 'border text-gray-500': variant === 'outlined' },
        className
      )}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};
