import React from 'react';

import { Container } from '@/components/Container';

const Footer = () => (
  <Container>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
      <div>
        <h3 className="uppercase font-bold text-lg mb-4">Techno<br/> long solution</h3>
        <p><span className="text-primary font-bold">Phone: </span>+62 8116551288</p>
        <p><span className="text-primary font-bold">E-Mail: </span>technolongsolutions@gmail.com</p>
        <p><span className="text-primary font-bold">Address: </span>LINGK 3</p>
        <p><span className="text-primary font-bold">&nbsp;&nbsp;&nbsp;</span>MEDAN MARELAN</p>
        <p><span className="text-primary font-bold">&nbsp;&nbsp;&nbsp;</span>RENGAS PULAU KOTA MEDAN 20255</p>
        <p><span className="text-primary font-bold">&nbsp;&nbsp;&nbsp;</span>INDONESIA</p>
      </div>
      <div>
        <h3 className="font-bold mb-4">Services</h3>
        <p>Web Development</p>
        <p>Web design & brending</p>
        <p>Full Optimization</p>
        <p>Research & Strategy</p>
        <p>Management & Marketing</p>
        <p>Free support</p>
      </div>
      <div>
        <h3 className="font-bold mb-4">About Company</h3>
        <p>About us</p>
        <p>Team</p>
        <p>Case studio</p>
        <p>Contacts</p>
      </div>
    </div>
    <div className="flex flex-col xs:flex-row items-center justify-start xs:justify-between my-10">
      <p>© 2023 Techno Long Solutions. All Rights Reserved.</p>

    </div>
  </Container>
);

export { Footer };
